import React, { useRef, useState, useCallback } from 'react';

import { Button, Container, FormGroup } from 'react-bootstrap';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, Toast } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function careerInsideTemplateForm({ pageContext }: any) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('* First Name is required'),
    lastName: Yup.string().required(' * Last Name is required'),
    email: Yup.string().email('Invalid email address').required('* Email is required'),
    resumeFile: Yup.mixed()
      .required('* Resume file is required')
      .test('fileSize', '* File size must not exceed 5MB', (value) => {
        if (value) {
          const fileSize = value?.size / 1024 / 1024;
          return fileSize <= 5;
        }
        return true;
      })
      .test('fileType', '* Only PDF and DOCX files are allowed', (value) => {
        if (value) {
          const acceptedFormats = [
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          ];
          return acceptedFormats.includes(value?.type || value.file?.type);
        }
        return true;
      }),
  });

  const processReCaptcha: any = useCallback(async () => {
    try {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return null;
      }
      const token = await executeRecaptcha('submitForm');
      return token || null;
    } catch (e) {
      console.log(e);
      return null;
    }
  }, [executeRecaptcha]);

  const formData = {
    firstName: '',
    lastName: '',
    email: '',
    jobTitle: pageContext.role,
    jobId: pageContext.slug.replace('/', ''),
    resumeFile: {},
  };

  const handleSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    const token = await processReCaptcha();
    const { firstName, lastName, email, jobTitle, resumeFile } = values;
    const formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('jobTitle', jobTitle);
    formData.append('jobId', pageContext.slug.replace('/', ''));
    formData.append('resumeFile', resumeFile);
    formData.append('token', token);
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_INT_URL}/careers-webhook`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (data.status === 200) {
        setToastMessage('Details sent Successfully');
        setShowToast(true);
        resetForm();
      }
    } catch (err) {
      setToastMessage('Error. Try Again!');
      setShowToast(true);
    }
  };
  return (
    <>
      <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {(formik) => {
          return (
            <Form className='mb-lg-5 mb-3  w-lg-50'>
              <div className='ms-lg-3 ps-lg-5 position-relative'>
                <p className='fnt-28 fnt-sm-14 fw-700 text-white pb-2'>Required field to apply</p>

                <div className='mb-4'>
                  <label className='mb-1 fnt-16 fnt-sm-14 fw-700 text-white d-block w-100'>
                    First Name
                  </label>
                  <Field
                    type='text'
                    placeholder='First name'
                    autoFocus
                    className='p-2 w-100  pe-3 fw-500  fnt-sm-12 lh-30'
                    name='firstName'
                    onChange={(e: any) => formik.setFieldValue('firstName', e.target.value)}
                  />
                  <ErrorMessage
                    name='firstName'
                    component='div'
                    className='error  fw-600 mt-2 mt-md-0 fnt-14 lh-20 lh-sm-10 text-danger text-end'
                  />
                </div>

                <div className='mb-4'>
                  <label className='mb-1 fnt-16 fnt-sm-14 fw-700 text-white d-block w-100'>
                    Last Name
                  </label>
                  <Field
                    type='text'
                    placeholder='Last name'
                    className='p-2 w-100  pe-3 fw-500  fnt-sm-12 lh-30'
                    name='lastName'
                    onChange={(e: any) => formik.setFieldValue('lastName', e.target.value)}
                  />
                  <ErrorMessage
                    name='lastName'
                    component='div'
                    className='error  fw-600 mt-2 mt-md-0 fnt-14 lh-20 lh-sm-10 text-danger text-end'
                  />
                </div>

                <div className='mb-4'>
                  <label className='mb-1 fnt-16 fnt-sm-14 fw-700 text-white d-block w-100'>
                    Primary email ID
                  </label>
                  <Field
                    type='text'
                    placeholder='Primary email ID'
                    className='p-2 w-100  pe-3 fw-500  fnt-sm-12 lh-30'
                    name='email'
                    onChange={(e: any) => formik.setFieldValue('email', e.target.value)}
                  />
                  <ErrorMessage
                    name='email'
                    component='div'
                    className='error  fw-600 mt-2 mt-md-0 fnt-14 lh-20 lh-sm-10 text-danger text-end'
                  />
                </div>

                <div className='mb-4'>
                  <label className='mb-1 fnt-16 fnt-sm-14 fw-700 text-white d-block w-100'>
                    Job role
                  </label>
                  <Field
                    type='text'
                    placeholder={pageContext.role}
                    disabled={true}
                    className='p-2 w-100  pe-3 fw-500  fnt-sm-12 lh-30 text-black'
                    name='jobTitle'
                    value={pageContext.role}
                  />
                </div>
                <div className='d-flex justify-content-between flex-column flex-lg-row mb-3 mb-md-0 d-block w-100'>
                  <div className='text-white fnt-18'>
                    <p className='mb-0 fnt-sm-12'>Upload your resume as docx or pdf (within 5MB)</p>
                  </div>
                  <input
                    type='file'
                    name='resumeFile'
                    accept='.docx,.pdf'
                    className='d-none'
                    ref={fileInputRef}
                    onChange={(e: any) => {
                      formik.setFieldValue('resumeFile', e.currentTarget.files[0]);
                    }}
                  />

                  <Button
                    className='btn-upload-resume my-auto col-lg-3'
                    onClick={handleButtonClick}
                  >
                    Browse
                  </Button>
                </div>
                {formik.values.resumeFile && (
                  <div className='mb-0 fnt-sm-12 fnt-18 text-white w-100'>
                    {formik.values.resumeFile?.name}
                  </div>
                )}
                <ErrorMessage
                  name='resumeFile'
                  component='div'
                  className='error  fw-600 mt-2 mt-md-0 fnt-14 lh-20 lh-sm-10 text-danger text-end'
                />
                <div className='d-flex justify-content-center justify-content-lg-end my-auto  mt-4'>
                  <Button
                    className='btn-contact-us border-0 col-lg-3'
                    type='submit'
                    disabled={formik.isSubmitting}
                  >
                    Apply now
                  </Button>
                </div>
                {showToast && (
                  <ToastContainer className='position-absolute bottom-0'>
                    <Toast
                      show={showToast}
                      onClose={() => setShowToast(false)}
                      delay={4000}
                      autohide
                      bg={toastMessage === 'Details sent Successfully' ? 'success' : 'danger'}
                      className='custom-toast'
                    >
                      <Toast.Body className='fw-600 fnt-22 fnt-sm-14 lh-24 '>
                        {toastMessage}
                      </Toast.Body>
                    </Toast>
                  </ToastContainer>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default careerInsideTemplateForm;
